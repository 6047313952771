img{
    max-width: 100%;
    border-radius: $border-radius-sm;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.img-stretch {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}